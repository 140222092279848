<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="usuarioPerfil">
                <v-card class="mx-auto" flat>
                    <v-img
                        height="200"
                        src="@/assets/image/logo/transporte.png"
                    ></v-img>
                    <v-row style="margin: 2.5%; position: absolute; top: 130px">
                        <v-list-item>
                            <v-list-item-avatar size="100">
                                <img
                                    src="@/assets/image/perfil/img_avatar.png"
                                    alt="John"
                                />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="title"
                                    style="margin-top: 20px"
                                >
                                    {{ eu.nome_completo }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ eu.cargo }} |
                                    {{ eu.email }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card class="mx-auto" tile>
                    <v-card-text>
                        <h2 class="subtitle-5">Segurança</h2>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text>
                        <InputTextV3
                            :value="'senhasupersegura'"
                            :contador="20"
                            :desativado="true"
                            tipo="password"
                            iconeAppend="mdi-eye-off"
                            rotulo="Senha dominio terranova.local"
                        />

                        <InputTextV3
                            v-model="senha"
                            :contador="20"
                            :regra="[rules.required, rules.min]"
                            :tipo="show1 ? 'text' : 'password'"
                            :desativado="true"
                            :clickAppend="
                                () => {
                                    show1 = !show1;
                                }
                            "
                            :iconeAppend="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            rotulo="Senha sistema local"
                        />

                        <v-col class="text-right">
                            <v-btn
                                class="text-capitalize mt-5 element-0"
                                color="success"
                                :disabled="true"
                                >Salvar</v-btn
                            >
                        </v-col>
                    </v-card-text>
                </v-card>

                <v-card class="mt-5" elevation="2">
                    <v-card-title> Endereco Principal</v-card-title>
                    <EnderecoForm
                        ref="enderecoPrincipalRef"
                        :tipoEndereco="1"
                        :itemsEstado="itemsEstado"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ApiService from '@/services/ApiService.js';
import Avatar from '@/components/Upload/Avatar.vue';

import InputTextV3 from '@/components/Form/InputTextV3.vue';

import EnderecoForm from '@/components/Parceiro/Endereco/EnderecoForm.vue';

export default {
    name: 'UsuarioPerfil',
    components: {
        Avatar,
        InputTextV3,
        EnderecoForm,
    },
    async mounted() {
        await this.getEstados();
        await this.dadosUsuario();
    },
    methods: {
        async dadosUsuario() {
            await ApiService({
                method: 'get',
                url: `V1/usuario/perfil`,
                data: {},
            })
                .then((resposta) => {
                    this.eu.nome_completo =
                        resposta.data.data.atributos.nome_completo;
                    this.eu.cargo = resposta.data.data.atributos.cargo;
                    this.eu.email = resposta.data.data.atributos.email;

                    if (!_.isEmpty(resposta.data.data.atributos.enderecos)) {
                        const enderecoPrincipal =
                            resposta.data.data.atributos.enderecos.find(
                                (endereco) => endereco.tipo_endereco === 1
                            );

                        this.$refs.enderecoPrincipalRef.preencheDados(
                            enderecoPrincipal
                        );
                    }
                })
                .catch((erro) => {
                    throw new Error(erro.message);
                });
        },
        async getEstados() {
            await ApiService({
                method: 'get',
                url: `V1/estado/lista?fields=uf`,
                data: {},
            })
                .then((resposta) => {
                    this.itemsEstado = resposta.data.data.map((item) => {
                        return {
                            label: item.atributos.uf,
                            value: item.id_registro,
                        };
                    });
                })
                .catch((erro) => {
                    throw new Error(erro.message);
                });
        },
    },

    data: () => ({
        eu: {
            nome_completo: '',
            cargo: '',
            email: '',
        },
        nome: '',
        login: '',
        descricao: '',
        email: '',
        senha: '',
        show1: false,
        rules: {
            required: (value) => !!value || 'Required.',
            min: (v) => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => "The email and password you entered don't match",
        },
        items: ['London', 'India', 'America'],
        itemsEstado: [],
    }),
};
</script>

<style scoped>
.usuarioPerfil {
    min-height: 300px;
}
</style>